import React, { Fragment, useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import './Resources/css/site.css';
import '../../Resources/Css/YouTubeEmbed.css'
import Common from '../../Shared/Common';
import AppWrapper from '../../Controls/Scafolding/AppWrapper';
import { NavMenu } from '../../Controls/NavMenu';

import Top from './Blocks/Top';
import About from './Blocks/About';
import Sponsors from './Blocks/Sponsors';
import Register from './Blocks/Register';
import Rules from './Blocks/Rules';
import Champions from './Blocks/Champions';
import { Gallery } from '../../Controls/Digital/Gallery';
import { TextHeader } from '../../Controls/Digital/TextHeaders';
import Block from '../../Controls/Scafolding/Block';
import Footer from './Blocks/Footer';
import ImageInsert from '../../Controls/Digital/ImageInsert';
import Dates from './Blocks/Dates';

const KingFishCup = (props) => {

    let logoIconPath = require("./Resources/Images/LogoCircleWhite.jpg");

    const [menu] = useState({
        hasLogo: true,
        logoUrl: logoIconPath,
        styles: {},

        text: 'Adreno Sydney Kingfish Cup 2024',
        textSmallDisplay: 'Kingfish Cup',
        icon: "",

        items: [
            { linkLabel: 'Home', linkUrl: '#top' },
            { linkLabel: 'About', linkUrl: '#about' },
            { linkLabel: 'Key Dates', linkUrl: '#dates' },
            { linkLabel: 'Sponsors', linkUrl: '#sponsors' },
            { linkLabel: 'The Champions', linkUrl: '#projects' },
            { linkLabel: 'Rules', linkUrl: '#rules' },
            { linkLabel: 'Register', linkUrl: '#register' },
            { linkLabel: 'Gallery', linkUrl: '#gallery' },
        ]
    });
    const [galleryImagesLeft] = useState(() => Common.ImportAllImagesInFolder(require.context('./Resources/Images/GalleryLeft', false)));
    const [galleryImagesRight] = useState(() => Common.ImportAllImagesInFolder(require.context('./Resources/Images/GalleryRight', false)));

    useEffect(() => {
        Common.traceInit("KFC App");
        ReactGA.initialize("G-HGMPD5EK03");
    });

    return (
        <AppWrapper wrapClass="kfc">
            <Top />
            <NavMenu menu={menu} offset="50" addClass="Menu sticky-top" />
            <About />
            <ImageInsert addClass="kfc-kill" />
            <Dates/>
            <Sponsors />
            <ImageInsert addClass="kfc-school" />
            <Champions />
            <Rules />
            <ImageInsert addClass="kfc-collage" />
            <Register />
            <Block name="gallery" isFluid={true}>
                <TextHeader text={
                    <Fragment>
                        some shots from previous cups...
                    </Fragment>
                } />
                <Gallery
                    imagesLeft={galleryImagesLeft}
                    imagesRight={galleryImagesRight}
                    placeholderSrc={null} />
            </Block>
            <Footer />
        </AppWrapper>
    );
}
export default KingFishCup;

